import React from 'react';

const ProductsPage: React.FC = () => {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Our Products</h1>
        
        <div className="products-grid">
          <div className="product-card">
            <h2>AI Inference API</h2>
            <p className="product-description">
              High-throughput AI inference endpoints supporting multiple modalities and use cases.
            </p>
            <h3>Features:</h3>
            <ul>
              <li>Text Generation & Completion</li>
              <li>Embedding Generation</li>
              <li>Text-to-Speech (TTS)</li>
              <li>Text-to-Image Generation</li>
              <li>Image-to-Text Analysis</li>
              <li>Multimodal Processing</li>
            </ul>
          </div>

          <div className="product-card">
            <h2>Enterprise Integration</h2>
            <p className="product-description">
              Secure deployment options for enterprise customers with complete privacy.
            </p>
            <h3>Features:</h3>
            <ul>
              <li>AWS PrivateLink Support</li>
              <li>VPC Peering</li>
              <li>VPN Access</li>
              <li>Bring-Your-Own-Cloud</li>
              <li>Custom Security Policies</li>
            </ul>
          </div>

          <div className="product-card">
            <h2>Professional Services</h2>
            <p className="product-description">
              Expert consulting and implementation services for AI integration.
            </p>
            <h3>Services:</h3>
            <ul>
              <li>Agentic Flows Implementation</li>
              <li>RAG System Design</li>
              <li>Function Calling Setup</li>
              <li>Data Distillation Workflows</li>
              <li>Custom AI Solutions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
