import React from 'react';
import PlatformRedirect from '../components/PlatformRedirect';

const HomePage = () => {
  return (
    <div className="home-page">
      <PlatformRedirect />
      <main className="hero-section">
        <div className="hero-content">
          <h1>Enterprise AI Infrastructure<br />Built for Scale</h1>
          <p className="hero-subtitle">Secure, high-performance AI solutions trusted by industry leaders</p>
          <div className="hero-cta">
            <button className="btn-primary" onClick={() => window.location.href = '/contact'}>Schedule Demo</button>
            <button className="btn-secondary" onClick={() => window.location.href = '/products'}>View Solutions</button>
          </div>
        </div>
      </main>
      <section className="features-section">
        <h2>Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Complete Privacy</h3>
            <p>Internal consumption only via VPN, AWS PrivateLink, or VPC Peering with zero data retention</p>
          </div>
          <div className="feature-card">
            <h3>Comprehensive API Suite</h3>
            <p>Access to embedding, TTS, TTL, vision, text-to-image, and multimodal endpoints</p>
          </div>
          <div className="feature-card">
            <h3>Enterprise Solutions</h3>
            <p>Optional bring-your-own-cloud deployment on AWS for enterprise customers</p>
          </div>
          <div className="feature-card">
            <h3>Professional Services</h3>
            <p>Expert consulting for Agentic flows, RAG implementation, and AI integration</p>
          </div>
          <div className="feature-card">
            <h3>High Performance</h3>
            <p>Optimized for high-throughput inference with uncensored and context-flexible models</p>
          </div>
          <div className="feature-card">
            <h3>Advanced Capabilities</h3>
            <p>Specialized in complex data distillation flows and red team operations</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
