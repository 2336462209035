import React from 'react';
import '../styles/global.css';

const ContactPage: React.FC = () => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // TODO: Implement form submission logic
  };

  return (
    <div className="page-container">
      <div className="content-section">
        <h1 className="api-key-title">Contact Us</h1>
        <p className="api-key-description">
          Ready to deploy enterprise-grade AI infrastructure? Contact us to discuss your enterprise AI needs, security requirements, and how Coregen Platform can provide the secure, high-performance infrastructure your business demands.
        </p>
        <div className="contact-info">
          <p>Email: contact@coregen.io</p>
          <p>Location: San Francisco, CA</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows={4} required></textarea>
          </div>
          <button type="submit" className="btn-primary">Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
