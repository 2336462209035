import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src="/logo.png" alt="Coregen AI" className="company-logo" />
        <Link to="/">Coregen</Link>
      </div>
      <div className="nav-links">
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/products" className="nav-link">Products</Link>
        <Link to="/pricing" className="nav-link">Pricing</Link>
        <Link to="/about" className="nav-link">About</Link>
        <Link to="/contact" className="nav-link">Contact</Link>
      </div>
      <div className="navbar-right">
        <button 
          className="btn-primary"
          onClick={() => window.location.href = '/contact'}
        >
          Get Started
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
