import React, { useState } from 'react';

const PlatformRedirect: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleRedirect = () => {
    document.cookie = "redirect_source=commercial_site; domain=.coregen.io; path=/";
    window.location.href = 'https://platform.coregen.io';
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="platform-redirect">
      <div className="platform-redirect-content">
        <span>Looking for the Coregen AI Platform?</span>
        <button 
          className="btn-secondary"
          onClick={handleRedirect}
        >
          Go to Platform
        </button>
        <button 
          className="platform-redirect-close"
          onClick={handleClose}
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default PlatformRedirect;
