import React from 'react';

const AboutPage: React.FC = () => {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>About Coregen Platform</h1>
        <div className="about-content">
          <section>
            <h2>Our Mission</h2>
            <p>
              At Coregen Platform, we're building the future of AI tooling for enterprises. 
              We recognize that as AI becomes the foundation of modern business operations, 
              organizations need secure, flexible, and powerful solutions that can adapt to their unique needs.
            </p>
          </section>

          <section>
            <h2>Our Expertise</h2>
            <p>
              We specialize in delivering high-throughput inference solutions with a focus on:
            </p>
            <ul>
              <li>Red team uncensored and context-flexible models</li>
              <li>Complex data distillation flows</li>
              <li>Agentic flows implementation</li>
              <li>RAG (Retrieval-Augmented Generation) systems</li>
              <li>Enterprise AI integration</li>
            </ul>
          </section>

          <section>
            <h2>Privacy & Security</h2>
            <p>
              We maintain the highest standards of data privacy and security:
            </p>
            <ul>
              <li>Zero data retention policy</li>
              <li>Internal consumption only via secure channels</li>
              <li>Support for AWS PrivateLink and VPC Peering</li>
              <li>Enterprise-grade security measures</li>
            </ul>
          </section>

          <section>
            <h2>Enterprise Solutions</h2>
            <p>
              Our enterprise solutions include:
            </p>
            <ul>
              <li>Bring-your-own-cloud deployment options (AWS)</li>
              <li>Professional services and consulting</li>
              <li>Custom AI implementation strategies</li>
              <li>24/7 enterprise support</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
