import React from 'react';

const PricingPage: React.FC = () => {
  return (
    <div className="page-container">
      <div className="content-section">
        <h1>Enterprise Pricing</h1>
        
        <div className="pricing-intro">
          <p>
            Coregen Platform offers flexible enterprise pricing based on your specific needs and usage patterns.
            Contact our sales team for a customized quote.
          </p>
        </div>

        <div className="pricing-grid">
          <div className="pricing-card">
            <h2>Enterprise Starter</h2>
            <ul>
              <li>Up to 1M API calls/month</li>
              <li>99.9% SLA guarantee</li>
              <li>Email support</li>
              <li>Basic security features</li>
              <li>Standard analytics</li>
            </ul>
            <button className="btn-primary">Contact Sales</button>
          </div>

          <div className="pricing-card featured">
            <h2>Premium Enterprise</h2>
            <ul>
              <li>Everything in Standard</li>
              <li>Priority support</li>
              <li>Advanced SLA</li>
              <li>Custom model training</li>
              <li>Dedicated account manager</li>
            </ul>
            <button className="btn-primary">Contact Sales</button>
          </div>

          <div className="pricing-card">
            <h2>Custom Deployment</h2>
            <ul>
              <li>Everything in Premium</li>
              <li>Bring-your-own-cloud</li>
              <li>Custom security policies</li>
              <li>24/7 premium support</li>
              <li>Professional services</li>
            </ul>
            <button className="btn-primary">Contact Sales</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
